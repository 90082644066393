import { Link } from 'react-router-dom'

// data
import { presenterData } from '../data/presenterData'

export default function Presenter({limit}) {
  return (
    /* Card Component */
    <div className="card-grid gap-4">
      {presenterData.slice(0, limit).map((presenter) => (
        <div className="card" key={presenter.id}>
          <Link to={`/presenters/${presenter.id}`} >
            <div>
              <img src={presenter.profileImage} alt={presenter.name} width={480} height={270} className="w-full object-cover"/>
              <div className="m-4">
                <h3 className="font-bold text-gray-700 text-sm">{presenter.name}</h3>
                <p className="text-gray-500 text-sm">{presenter.description.substring(0, 100)}...</p>
                <button className="text-gray-700 font-bold text-xs">More</button>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}
