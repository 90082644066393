

// components

export default function About() {

  return (
    <div className="p-10 grid md:grid-cols-1 gap-8">
      <div>
        <h1 className="text-center font-normal">
          About Happy Radio UK
        </h1>
        <p>
        Happy Radio launched in 2022 with a mission to deliver a great mix of music, positivity, and local connection to Manchester, Cheshire, and the North West. Created by a group of broadcasting and event experts , the station quickly became a favorite for listeners seeking a mix of nostalgia, expertly programmed music variety, and engaging, locally-focused content.
        <br></br>
        <br></br>
        With a stellar team of presenters, including Steve Penk, Chelsea Norris, Spence MacDonald, Darren Proctor, and many more, Happy Radio delivers personality-driven shows that resonate with listeners of all ages. The team brings warmth, humor, and heart to every show , creating a unique and relatable listening experience.
        <br></br>
        <br></br>
        Proudly championing local businesses, events, and communities, Happy Radio supports Manchester Cheshire and the North West in every way. From highlighting local stories and events to promoting charity initiatives and community activity, the station has become a vital part of the region's heartbeat.
        <br></br>
        <br></br>
        More and more people are joining the Happy Radio family every week as the station continues to grow its audience.        <br></br>
        </p>
      </div>
      {/* <div>
        <ContactForm />
      </div> */}
    </div>
  )
}
