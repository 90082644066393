import { format, eachDayOfInterval } from 'date-fns'
import { useState } from 'react';
import { Link } from 'react-router-dom';

// data
import { eventsData } from '../data/eventsData'

export const today = new Date()
export const day = format(today, 'eee')
export const date = format(today, 'd')
export const hour = format(today, 'h')

const pastDay = new Date(new Date().setDate(new Date().getDate() - 6));
const futureDay = new Date(new Date().setDate(new Date().getDate() + 6));

const pastDays = eachDayOfInterval({
  start: pastDay,
  end: new Date(new Date().setDate(new Date().getDate() - 1))
})

const futureDays = eachDayOfInterval({
  start: today,
  end: futureDay
})

const timeline = pastDays.concat(futureDays)


export default function Scheduler() {
  const [clickedDay, setClickedDay] = useState(day)
  const [clickedDate, setClickedDate] = useState(date)

  // handle click event
  const viewSchedule = (e) => {
    setClickedDay(e.target.firstChild.innerText.toUpperCase())
    setClickedDate(e.target.lastChild.innerText)
  }

  return (
    <>
      <div className="timeline">      
          {timeline.map(date => (
            <div
              key={date} 
              onClick={viewSchedule}
              className={`timeline-date ${format(date, 'd') === clickedDate ? "active" : ''}`}
              >
              <li className='pointer-events-none font-light text-sm uppercase'>{format(date, 'eee')}</li>
              <li className='pointer-events-none font-medium text-lg'>{format(date, 'd')}</li>
            </div>
          ))}
      </div>
      <div className="events">
        {eventsData.sort((a, b) => a.time - b.time).map((event) => {
          return event.day.map(day => day.toUpperCase()).includes(clickedDay.toUpperCase())
          ? (
            <Link to={`/schedule/${event.id}`} key={event.id} className="block hover:bg-gray-50 transition-colors">
              <li className="event-card">
                <img src={event.image} alt={event.name} width={100} height={100}/>
                <div className="pl-4">
                  <div className="flex items-center">
                    <p className="text-sm font-semibold">{event.name}<span className="font-normal">&nbsp;|&nbsp;{event.time}:00</span></p>
                  </div>
                  <p className="text-xs md:text-sm text-gray-500">{event.description}</p>
                </div>
              </li>
            </Link>
          )
        : null })}
      </div>
    </>
  )
}