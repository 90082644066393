import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

// assets
import logo from '../assets/logos/logo-100.png'

// data
import { eventsData } from '../data/eventsData'
import { Link } from 'react-router-dom';

const findCurrentShow = (shows, currentHour, currentDay) => {
  // Sort shows by time to handle overlaps
  const sortedShows = [...shows].sort((a, b) => a.time - b.time);
  
  // Find the show that matches the current time and day
  return sortedShows.find(event => {
    const matchesDay = event.day.some(d => d.toLowerCase() === currentDay.toLowerCase());
    
    // Get the show's duration (assuming each show runs until the next show starts)
    const nextShow = sortedShows.find(s => 
      s.time > event.time && 
      s.day.some(d => d.toLowerCase() === currentDay.toLowerCase())
    );
    
    const endTime = nextShow ? nextShow.time : 24;
    const isCurrentlyPlaying = currentHour >= event.time && currentHour < endTime;
    
    return matchesDay && isCurrentlyPlaying;
  });
};

export default function Player() {
  const currentHour = new Date().getHours();
  const currentDay = new Date().toLocaleDateString('en-US', { weekday: 'short' });
  const currentShow = findCurrentShow(eventsData, currentHour, currentDay);  
  
  // Debug logs
  // console.log('Current Hour:', currentHour);
  // console.log('Current Day:', currentDay);
  // console.log('Current Show:', currentShow);

  return (
    <div className="player ">
      <div className="flex items-center gap-2 max-w-full overflow-hidden">
        <img 
          src={logo} 
          alt='Happy Radio UK Logo' 
          className='bg-theme w-12 h-12 md:w-16 md:h-16 flex-shrink-0' 
        />
        
        {currentShow ? (
          <Link to={`/schedule/${currentShow.id}`}>
          <div className="flex items-center gap-2 flex-1 min-w-0 text-black">
            <div className="w-12 h-12 md:w-16 md:h-16 overflow-hidden flex-shrink-0">
              <img 
                src={currentShow.image} 
                alt={currentShow.name} 
                className="w-full h-full object-cover"
              />
            </div>
            <div className="flex-1 min-w-0 pr-2">
              <p className="font-semibold text-xs md:text-sm truncate">
                Now Playing: {currentShow.name}
              </p>
              <p className="text-xs text-gray-500 line-clamp-2">
                {currentShow.description}
              </p>
            </div>
          </div>
          </Link>
        ) : (
          <div className="flex-1 min-w-0 pr-2">
            <p className="font-semibold text-sm truncate">Happy Radio UK</p>
            <p className="text-xs text-gray-500 truncate">
              Playing the songs that make you smile!
            </p>
          </div>
        )}
      </div>

      <AudioPlayer
        autoPlay={true}
        src="https://stream.aiir.com/yofqmvmgihuuv"
        controls
        customAdditionalControls={[]}
        layout="horizontal"
        showJumpControls={false}
        defaultCurrentTime=""
        defaultDuration=""
        className="player-override"
      />
    </div>
  )
}
