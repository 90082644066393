//assets
import applelogo from "../assets/logos/app-store.png";
import googlelogo from "../assets/logos/play-store.png";
import web from "../assets/howtolisten/Happy-Website.png";
import app from "../assets/howtolisten/Happy-App.png";
import tv from "../assets/howtolisten/Happy-TV.png";
import car from "../assets/howtolisten/Happy-Car.png";
import google from "../assets/howtolisten/Happy-Google.png";
import alexa from "../assets/howtolisten/Happy-Alexa.jpeg";
import radioplayer from "../assets/howtolisten/Happy-Radio-Player.png";
import sonos from "../assets/howtolisten/Happy-Sonos.png";

export default function HowToListen() {
  return (
    <div className="py-10">
      <h1>How To Listen Happy Radio UK</h1>
      <p className="text-center font-light pb-8">
        On the Happy Radio UK App, On TV channel 277, On your Smart Speaker,
        Online and on DAB across the North West!
      </p>
      <div className="grid grid-cols-1 gap-8 mx-4 sm:grid-cols-3 lg:grid-cols-4 lg:mx-48">
        <li className="card">
          <img
            src={car}
            alt="How To Listen Happy Radio UK"
            width={1280}
            height={1280}
            className="w-full object-cover"
          />
          <div className="m-4">
            <h2 className="font-bold text-gray-700 text-xl pb-2">In the Car</h2>
            <p className="text-gray-500 text-sm">
              We're Available on DAB Radio across the North West, in Manchester
              (Manchester 10B Multiplex), in Cheshire, Chester, North Wales and
              Liverpool (NE Wales & W Cheshire 10D) and in Blackpool and the
              Flyde Coast (12A Flyde Coast).
              <br />
              <br />
              Just search or scroll through your DAB radio in your car for Happy
              Radio UK, rescan if you can't see us listed.
            </p>
          </div>
        </li>
        <li className="card">
          <img
            src={alexa}
            alt="How To Listen Happy Radio UK"
            width={1280}
            height={1280}
            className="w-full object-cover"
          />
          <div className="m-4">
            <h2 className="font-bold text-gray-700 text-xl pb-2">On Alexa</h2>
            <p className="text-gray-500 text-sm">
              It's very easy to listen to Happy Radio UK on your Alexa device.
              Here's what you need to do.
              <br />
              <br />
              Enable the Happy Radio UK skill on your Alexa Speaker by saying
              'Alexa Enable Happy Radio UK Skill' or by tapping this
              <a
                className="link"
                href="https://www.amazon.co.uk/PlayoutONE-Happy-Radio-UK/dp/B09RB2R4Z8/ref=sr_1_3?crid=27QJ9O1ZWC0IR&keywords=Happy+radio&qid=1665402530&qu=eyJxc2MiOiIwLjAwIiwicXNhIjoiMC4wMCIsInFzcCI6IjAuMDAifQ%3D%3D&s=digital-skills&sprefix=happy+radio+%2Calexa-skills%2C54&sr=1-3"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                link
              </a>{" "}
              then just say "Alexa, play Happy Radio UK".
              <br />
              <br />
              Want to play Happy Radio without lifting a finger? While near your
              Amazon Echo, say: "Alexa, enable Happy Radio UK Skill" and then
              simply say: "Alexa, play Happy Radio UK".
            </p>
          </div>
        </li>
        <li className="card">
          <img
            src={app}
            alt="How To Listen Happy Radio UK"
            width={1280}
            height={1280}
            className="w-full object-cover"
          />
          <div className="m-4">
            <h2 className="font-bold text-gray-700 text-xl pb-2">On the App</h2>
            <p className="text-gray-500 text-sm pb-4">
              Listen on your smartphone or tablet by downloading the Happy Radio
              UK app.
            </p>
            <div className="flex flex-col md:flex-row gap-4 md:gap-6">
              <a
                href="https://apps.apple.com/gb/app/happy-radio-uk/id1615406580"
                target="_blank"
                rel="noreferrer"
              >
                <img src={applelogo} alt="App Store" width={200} height={200} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.happyradiouk.player"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={googlelogo}
                  alt="Play Store"
                  width={200}
                  height={200}
                />
              </a>
            </div>
          </div>
        </li>
        <li className="card">
          <img
            src={web}
            alt="How To Listen Happy Radio UK"
            width={1280}
            height={1280}
            className="w-full object-cover"
          />
          <div className="m-4">
            <h2 className="font-bold text-gray-700 text-xl pb-2">
              At Home or In The Office
            </h2>
            <p className="text-gray-500 text-sm">
              Listen through our website or on your TV on Channel 277 (Freeview
              Smart TV) or on our smart speaker apps:
            </p>
          </div>
        </li>
        <li className="card">
          <img
            src={tv}
            alt="How To Listen Happy Radio UK"
            width={1280}
            height={1280}
            className="w-full object-cover"
          />
          <div className="m-4">
            <h2 className="font-bold text-gray-700 text-xl pb-2">
              On Your Smart TV
            </h2>
            <p className="text-gray-500 text-sm">
              If you have a smart TV at home simply select channel 277 and
              locate the Happy Radio logo - Click on the logo and you can listen
              to Happy Radio live through your TV.
            </p>
          </div>
        </li>
        <li className="card">
          <img
            src={google}
            alt="How To Listen Happy Radio UK"
            width={1280}
            height={1280}
            className="w-full object-cover"
          />
          <div className="m-4">
            <h2 className="font-bold text-gray-700 text-xl pb-2">
              On Google Smart Speaker
            </h2>
            <p className="text-gray-500 text-sm">
              For Google Smart Speakers; just say 'Hey Google, talk to Happy
              Radio UK'.
            </p>
          </div>
        </li>
        <li className="card">
          <a href="/radioplayer/" target="_blank" rel="noreferrer">
            <img
              src={radioplayer}
              alt="How To Listen Happy Radio UK"
              width={1280}
              height={1280}
              className="w-full object-cover"
            />
            <div className="m-4">
              <h2 className="font-bold text-gray-700 text-xl pb-2">
                On Radio Player
              </h2>
              <p className="text-gray-500 text-sm">
                For Google Smart Speakers; just say 'Hey Google, talk to Happy
                Radio UK'.
              </p>
            </div>
          </a>
        </li>

        <li className="card">
          <img
            src={sonos}
            alt="How To Listen Happy Radio UK"
            width={1280}
            height={1280}
            className="w-full object-cover"
          />
          <div className="m-4">
            <h2 className="font-bold text-gray-700 text-xl pb-2">On Sonos</h2>
            <p className="text-gray-500 text-sm">
              To find Happy Radio UK on your Sonos system:
              <br />
              <br />
              <ol>
                <li>Open the Sonos app on your device.</li>
                <li>Go to the Search tab and type “Happy Radio UK.”</li>
                <li>If it’s listed, select it to play directly.</li>
                <li>
                  Alternatively, add the Radioplayer service in the Sonos app,
                  then search for “Happy Radio UK” within that service.
                </li>
              </ol>
              <br />
              If you can’t find it, ensure your Sonos app and streaming services
              are updated.
              {/* <br />
              <br />
              You can then just put the following stream URL into the box:
              <a className="link" href="https://playoutonestreaming.com/proxy/happy?mp=/stream"> https://playoutonestreaming.com/proxy/happy?mp=/stream</a>
              <br />
              <br />
              Once confirmed; Happy Radio UK will appear in your 'My Radio Stations' in your sonos app. */}
            </p>
          </div>
        </li>
      </div>
    </div>
  );
}
