import { useParams, useNavigate, Link } from "react-router-dom"

// data
import { presenterData } from "../data/presenterData"

export default function Profile() {
  const { id } = useParams()
  const navigate = useNavigate()

  // Find a specific object from an array by matching an ID 
  const presenter = presenterData.find(presenter => presenter.id === Number(id))

  return (
    <div className="bg-grey-100 text-grey-700 min-h-[360px] flex justify-center relative">
      <Link className="absolute left-2 top-2 md:left-4 md:top-4 text-sm text-black hover:text-black  hover:underline" to="/presenters">&larr; Back to presenters</Link>
      <div className="grid md:grid-cols-2 text-center md:text-left justify-between items-center gap-6 p-8 my-8 max-w-6xl">
        <div className="justify-self-center md:justify-self-start">
          <img 
            src={presenter.profileImage} 
            alt={presenter.name} 
            height={480} 
            width={480} 
            className="h-48 w-48 md:w-80 md:h-80 rounded-full object-cover"
          />
        </div>
        <div>
          <h1 className="mb-1 pb-1">{presenter.name}</h1>
          <h2 className="text-theme text-sm pb-4 mb-4 text-center">Happy Radio UK Presenter</h2>
          <p className="text-grey-500 text-sm">
            {presenter.description}
          </p>
        </div>
      </div>
    </div>
  )
}
