import { useEffect, useState } from "react";
import XMLParser from "react-xml-parser";
// import { formatDistance, subDays, parse, parseISO, toDate } from 'date-fns'

// components
// import { Tabs } from './Tabs';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";

//assets
import MeNewsLogo from "../assets/logos/me-news-logo.png";

export default function MeNews() {
  const [newsItems, setNewsItems] = useState([]);
  const [whatsOnItems, setWhatsOnItems] = useState([]);

  useEffect(() => {
    fetch("/api/meNews")
      .then((response) => response.text())
      .then((data) => {
        var xml = new XMLParser().parseFromString(data);
        // console.log(xml);
        const channel = xml.getElementsByTagName("channel");
        // console.log(channel);
        const items = channel[0].getElementsByTagName("item");
        setNewsItems(items);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    fetch("/api/meNewsWhatsOn")
      .then((response) => response.text())
      .then((data) => {
        var xml = new XMLParser().parseFromString(data);
        // console.log(xml);
        const channel = xml.getElementsByTagName("channel");
        // console.log(channel);
        const items = channel[0].getElementsByTagName("item");
        setWhatsOnItems(items);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div>
      <div className="flex justify-center items-center font-light gap-2">
        Powered by
        <img
          src={MeNewsLogo}
          alt="Manchester Evening News Logo"
          height={140}
          width={140}
        />
      </div>
      <div className="mt-8">
        <Tabs defaultValue="news" className="flex flex-col items-center">
          <TabsList className="">
            <TabsTrigger value="news">News</TabsTrigger>
            <TabsTrigger value="whatson">What's On</TabsTrigger>
          </TabsList>
          <TabsContent value="news">
            <div className="news mt-8">
              {newsItems ? (
                newsItems.slice(0, 8).map((item) => (
                  <a
                    href={item.children[1].value}
                    target="_blank"
                    rel="noreferrer"
                    key={item.children[1].value}
                  >
                    <div className="news-card">
                      <img
                        src={item.children[8].attributes.url.replace(
                          "70x70",
                          "384x216"
                        )}
                        alt={item.children[6].value}
                      />
                      <div className="m-4">
                        <h3 className="font-bold text-gray-700 text-sm mb-4">
                          {item.children[0].value.replaceAll(/%20/g, ' ')}
                        </h3>
                        <p className="text-gray-500 text-sm">
                          {item.children[6].value} |{" "}
                          {item.children[4].value.slice(0, -9)}
                        </p>
                      </div>
                    </div>
                  </a>
                ))
              ) : (
                <h2>Loading...</h2>
              )}
            </div>
          </TabsContent>
          <TabsContent value="whatson">
            <div className="news mt-8">
              {whatsOnItems ? (
                whatsOnItems.slice(0, 8).map((item) => (
                  <a
                    href={item.children[1].value}
                    target="_blank"
                    rel="noreferrer"
                    key={item.children[1].value}
                  >
                    <div className="news-card">
                      <img
                        src={item.children[8].attributes.url.replace(
                          "70x70",
                          "384x216"
                        )}
                        alt={item.children[6].value}
                      />
                      <div className="m-4">
                        <h3 className="font-bold text-gray-700 text-sm mb-4">
                          {item.children[0].value}
                        </h3>
                        <p className="text-gray-500 text-sm">
                          {item.children[6].value} |{" "}
                          {item.children[4].value.slice(0, -9)}
                        </p>
                      </div>
                    </div>
                  </a>
                ))
              ) : (
                <h2>Loading...</h2>
              )}
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
