import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { lazy, Suspense } from "react";
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/react'

// styles

// utility
import ScrollToTop from "./utils/ScrollToTop";

// pages
import Home from "./pages/Home";
import About from "./pages/About";
import Schedule from "./pages/Schedule";
import Presenters from "./pages/Presenters";
import Profile from "./pages/Profile";
import HowToListen from "./pages/HowToListen";
import Advertise from "./pages/Advertise";
import Competition from "./pages/Competition";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Show from "./pages/Show";

// components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Player from "./components/Player";

// const Schedule = lazy(() => import("./pages/Schedule")) - TechBase Code Splitting

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <div className="mx-auto bg-gray-100">
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/schedule" element={<Schedule />} />
            <Route exact path="/schedule/:id" element={<Show />} />
            <Route exact path="/presenters" element={<Presenters />} />
            <Route exact path="/presenters/:id" element={<Profile />} />
            <Route exact path="/how-to-listen" element={<HowToListen />} />
            <Route exact path="/advertise" element={<Advertise />} />
            <Route exact path="/competition" element={<Competition />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/*" element={<NotFound />} />
          </Routes>
          <Footer />
          <Player />
          <Analytics />
          <SpeedInsights />
        </div>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
