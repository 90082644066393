export let presenterData = [
  {
    id: 1,
    name: `Spence Macdonald`,
    description: `
    Growing up in Manchester you would have heard Spence on Piccadilly Radio, then on the Key 103 Evening Show and two stints on the Breakfast Show with his 'Whysits' and the no expense spared competition "Down the Pan"

    Spence has done radio all over the country, Imagine in Stockport, The Bay in Morecambe, Signal 1 in Stafford, Century FM, Heart and at Radio 1. 

    Spence is on Happy Radio Breakfast Monday to Friday 7-10am and on a Sunday afternoon from 2 – 4pm. 

    On the breakfast show, Spence has everything you need to know to start your day with all the latest news and travel information, and he'll definitely put a smile on your face with his outlook on life.
    `,
    profileImage: '/presenters/Spence-Macdonald.JPG'
  },
  {
    id: 2,
    name: `Darren Proctor`,
    description: `
    Darren Proctor is on Happy Radio, weekdays from 10-1pm with our friends at Franklyn, playing the songs that make you smile while you're at work or driving around Manchester and Cheshire. 

    If there's something going on across Manchester and Cheshire – Darren will know about it and probably have someone on the show who can tell you more about it, He's got 'Proctor's Pop Quiz' testing your musical knowledge each hour and you're bound to see him out and about doing his show live from various locations.

    Darren hosts a lot of the events in the area and you can see him DJing at various bars or turning parks like the Royal Cheshire County Show or the Hale Barns Carnival fields into Nightclubs with his legendary Grown up Disco – which you can hear every Saturday Night from 8pm as we go in the mix as part of Manchester's Weekend.

    Darren started out in the industry as a club DJ in 1990, where he was voted "Young DJ of the year" and in the past 30 years has performed DJ sets at venues and events across Europe including performances at The Manchester Arena and Festivals across the country. 

    Darren is well known for his engaging radio shows, previously with Bauer's Greatest Hits Radio, Key 103, and Radio City, his North West Breakfast Show on Century FM and presenting of the Pepsi Chart Show across the UK. Give him a listen on Happy Radio. 
    `,
    profileImage: '/presenters/Darren-Proctor.JPG'
  },
  {
    id: 3,
    name: `Steve Penk`,
    description: `
    After many years we managed to get the master of the wind-ups, the radio legend that is Steve Penk is back on the radio across Manchester and Cheshire and you can join him each weekday lunchtime from 1 til 3pm.

    Penky starts with an hour of classic songs on the Rewind – and you can get in touch to suggest one of your favourites, it could be a song you don't usually hear much on the radio anymore, a song from a specific time in your life, or maybe it's the first song you ever bought. Can you remember yours?

    Pick your favourite song for the rewind here

    At 2pm, we go back in time again but this time with your favourite wind-ups from back in the day, what was yours? Was it the "Mop-head for sale", the 'Carpet-Fitters job application' or were you a fan of Stefano Penkino – the Amaaaazing man, they're here each weekday afternoon to put a smile on your face. 

    Steve started his radio career at Manchester's Piccadilly Radio at the age of 16, Steve eventually landed the breakfast show when the station had become Key 103. The show was a huge success, achieving record audience figures, still unrivalled today. He moved to London to host mid-mornings on Capital in 1997 and then to Virgin Radio in 2001 and you may remember those Breakfast Shows on The Revolution.
`,
    profileImage: '/presenters/Steve-Penk.JPG'
  },
  {
    id: 4,
    name: `Lauren Simon`,
    description: `
    Each afternoon we have everybody's favourite 'House wife'– Lauren Simon playing an hour of songs that make you smile when you're on the school run or need a bit of an afternoon pick me up. 

Lauren is on The Real Housewives of Cheshire and brings the glamour, the lifestyle and the cheekiness to your afternoon, from the real-life antics of your favourite Housewives, to her surprise bond with Louis Walsh and Sharon Osbourne on Celebrity Big Brother 2024, we get all the latest gossip – just don't ask her to play Ronan Keating – Lovin' Each Day as it brings back memories of sitting in a skip all day with Louis Walsh – (yeah don't ask!)
`,
    profileImage: '/presenters/Lauren-Simon.JPG'
  },
  {
    id: 5,
    name: `Ian Roberts`,
    description: `
    Ian Roberts who became well-known for his popular afternoon show on Rock FM and on Magic Radio; heads up the Drivetime show from 4-7pm here on Happy Radio, Joined by former Channel M Breakfast host.

    The Drivetime show with Ian is fun, friendly & full of laughs, with great music and full of features on what's trending and new in the area.
    `,
    profileImage: '/presenters/Ian-Roberts.jpeg'
  },
  {
    id: 6,
    name: `Sean Goldsmith`,
    description: `
Sean Goldsmith has been on the airwaves since 1992; having previously worked on Bauer City 2 across the north of England, as well as Trent FM, Leicester Sound, Mercia FM, Century 106, Peak FM and Viking FM.

Sean Goldsmith wakes you up on the Weekend Breakfast show from 7-10am, playing the songs that make you smile and giving you his take on what's happening that day, and being the father of three boys there's always something going on with the kids so the Breakfast Show is like his little retreat, why not join him?
    `,
    profileImage: '/presenters/Sean-Goldsmith.JPG'
  },
  {
    id: 7,
    name: `Chelsea Norris`,
    description: `
    Chelsea Norris is on Happy Radio EVERY Saturday morning from 10, with all the songs that make you smile, and she likes to take you on her life journey with her 'Song of the Week' - Chelsea admits that her music taste is very eclectic, ranging from The Supremes to Bon Jovi, Kylie to Eminem, but the song she chooses will always have a meaning.

Chelsea is a proud Mum of 2 (or 3 if you include the dog) and has a shop called Minnie & Ted's in Bramhall named after..... well, its not both the children's names so she has a lot of explaining to do later in life.
 
Chelsea previously presented the Breakfast show On BBC Radio Manchester and before that she co-presented with Mike Toolan on Key 103 Breakfast for over 11 years and won the prestigious Sony Radio Best Breakfast Show award. 

Chelsea loves the songs we play on Happy Radio and her favourite show on the radio is Pete Price with his Guilty Pleasures – but to find out why you'll have to listen for yourself.
    `,
    profileImage: '/presenters/Chelsea-Norris.JPG'
  },
  {
    id: 8,
    name: `Paul Crone`,
    description: `
   For more than 30 years, Paul has become one of the most familiar faces and voices across the North West of England. 

His many broadcasting skills lie in his diversity to adapt to the versatile range of stories the popular nightly regional news programme Granada Reports covers. 

From a man who has slammed a piano lid down on Gary Barlow's hands, babysat for Phil Collins sons, and has frequently trod the boards in theatres across the North West, Paul Crone delivers his 'Cronezone' show every weekend on Happy Radio!

Become one of the growing number of Crone-Zoners, this Sunday morning from 10am
    `,
    profileImage: '/presenters/Paul-Crone.JPG'
  },
  {
    id: 9,
    name: `Andy Hoyle`,
    description: `
Andy Hoyle plays the songs that make you smile on the Late Show Monday to Thursday from 10pm – 1am, Andy's had over 30 years experience in local radio, presenting and managing various radio stations, with his distinctive voice you can hear Andy across the UK on various commercials, he narrates TV, he's the voice of some video games AND he reads some of our news bulletins too.

Mr Versatile, Andy, is also on Saturday afternoon from 2 – 6, where he's got a round-up of all the sports going on across Manchester and Cheshire. Plus, you can find out what sports clubs are meeting or which open events are happening near you for you to get involved in.
    `,
    profileImage: '/presenters/Andy-Hoyle.JPG'
  },
  {
    id: 10,
    name: `Liam Fox`,
    description: `
Liam Fox the man with a face for TV and Radio! Famously known for his role as Dan Spencer on Emmerdale for 12 years, Liam is usually heard on Happy Radio every Saturday afternoon from 2-6pm for the Saturday Sports Roundup, but is currently taking a break to star as 'Grandpa Potts' in the musical 'Chitty Chitty Bang Bang' which is touring the UK. Find out when Liam is appearing near you at  (chittyontour.com) 

Liam is Manchester born and bred and when he's back will keep us informed of all the local sports activities and events across Manchester and Cheshire, along with The Songs That Make You Smile and some fantastic stories of his life growing up in Manchester. The big question is, is he a red or a blue?! Listen when he's back to find out!!
    `,
    profileImage: '/presenters/Liam Fox.JPG'
  },
  {
    id: 11,
    name: `Paul Cambray`,
    description: `
    Paul Cambray is the Happy Radio Hunk, he's a professional personal fitness trainer by trade but has worked hard and always wanted to get into broadcasting and you can now hear him each weekday morning for his Early Breakfast Show from 5-7am.
 
Being active is very important to Paul, and not just physically, he likes to get your brain working early each day with his various themes on the show, although he does go easy on you at 10 to 6 with 'The Snooze', if you've been on the nightshift, this gives you 10 minutes to relax and have a break from work or if you don't have to be up til 6am, he's your virtual Snooze button on your alarm clock and he'll chill you out and ease you into the day.

His various themes are:

Motivation Monday – If there's a song that inspires you let him know and he'll play it at 6:30am

Tuesday & Thursday - We go Back in Time with a Mystery Year, 3 songs, 2 clues and 1 year – You just have to work it out without looking it up.

Way Back Wednesday - Paul takes you on a journey through the decades, one song from this month in each decade from the 60s til now.

Film Friday – Songs from the big screen but can you work out the link between them?
    `,
    profileImage: '/presenters/Paul-Cambray.JPG'
  },
  {
    id: 12,
    name: `Disco Di`,
    description: `
This show is the one that made us go and buy a glitterball for the studio, Disco Di is here every Friday night to officially start Manchester's Weekend.

3 hours of disco tunes and floor fillers, if there's a song you like to dance to – it'll be here on Happy Radio.

Disco Di hails from Rochdale and has always been the one to get a party going, she gets her lippy on, pours a glass of something nice and is not afraid of being first on the dance floor So get her on this Friday and you'll definitely be in the mood for a party this weekend.
    `,
    profileImage: '/presenters/Disco-Di.JPG'
  },
  {
    id: 13,
    name: `Ian Finch`,
    description: `
Each Sunday, Ian takes us back in time on The Happy Chart Show playing songs from the charts from two separate years. It's an hour of songs from Year 1 between 4 and 5, then an hour of songs from the next chosen year til 6pm.

Each week he'll change the years and listen out for the weekly "Dose of Cheese", yes those novelty hits that made the charts back then, Ian is not afraid to play again now. Listen back to the music of your life, this Sunday afternoon on Happy Radio.
    `,
    profileImage: '/presenters/Ian-Finch.JPG'
  },
  {
    id: 14,
    name: `Charlie Cee`,
    description: `
    Fancy a bit of Funk to spice up your weekend, Charlie Cee is on Happy Radio every Friday and Sunday night from 10 playing the best in Motown, Hip Hop, Soul and R&B.

From Jacki Wilson to Bell Biv DeVoe, Betty Everett to Mary J Blige, there's only one place you can hear Charlie's Jam and that's on Happy Radio.
    `,
    profileImage: '/presenters/Charlie-Cee.JPG'
  },
  // {
  //   id: 15,
  //   name: `Mike Maguire`,
  //   description: `
  //   Celebrity Impressionist Mike Maguire has been taking the comedy circuit by storm for decades and the man with a thousand voices can now be heard on Happy Radio every Sunday afternoon from 2-4pm with sidekick Spence MacDonald.
  //   Sunday afternoon fun with with hilarious stories, fantastic celebrity impressions and the songs that make you smile!
  //   `,
  //   profileImage: '/presenters/Mike-Maguire.JPG'
  // },
  {
    id: 16,
    name: `Fiona Sadler`,
    description: `
    Fiona Sadler is a radio broadcaster who learned to talk before she could walk and when she isn't behind a microphone can be found in water.
    She has a natural chatty style with a genuine warmth and a passionate delivery. Having worked on various platforms and for some incredible brands from Heat to Heart, Capital to Key 103, Fiona has a wealth of talent and is never short of something to say.
    Being a presenter doesn't necessarily mean being in a studio and Fiona has hosted many events proving that she is as versatile as she is accomplished. From fashion shows to food tastings, from awards ceremonies to gigs.
    Fiona has a wicked sense of humour and has often been asked to try stand-up but is not convinced! Listen in to Fiona every evening from 7-10pm and you'll find you have a new best friend!
    `,
    profileImage: '/presenters/Fiona Sadler.JPG'
  },
  {
    id: 17,
    name: `Jonathan Miles`,
    description: `
    Jonathan Miles is your host for Guilty Pleasures every Saturday from 6-8pm.

Jonathan, best known for his iconic work on Key 103's Quiet Storm, brings his charm and unmatched ability to create the perfect start to your Saturday evening.

It's two hours of pure fun and nostalgia, playing the songs you secretly love but don't always admit to!
    `,
    profileImage: '/presenters/Jonathan-Miles.jpeg'
  },
  {
    id: 18,
    name: `Sam Walker`,
    description: `
Sam is another of Manchester's most loved presenters known from her days at Key103 and BBC Radio 5 Live. Sam says Happy Radio is everything she thinks life should be - Friendly and fun with fantastic music that puts you in a good mood.

Sam definitely puts you in a good mood with the goings on in her daily life, Recently Sam has taken up running and took us all with her as she ran the London Marathon in 2023 and 2024 raising lots of money for charity. Sam loves going to concerts and gigs and some of her best gigs have been right here in Manchester and Cheshire. 

Each weekend, Sam is here from Midday til 2pm on both Saturday and Sunday, playing the songs that make you smile and showcasing the music of a local band or singer, she's featured James, Lisa Stansfield, Atomic Kitten, The Bee Gees, Harry Styles and many more.
    `,
    profileImage: '/presenters/Sam-Walker.PNG'
  },
  {
    id: 19,
    name: `Faye Bamford`,
    description: `
    Faye Bamford has been chatting and laughing behind a mic for 20 years. Whether it's radio presenting or voiceovers, she loves a natter and coming up with fun, upbeat and creative content. 

    Starting out presenting at her home station Rock FM in 2004, Faye has moved around various commercial stations over the years, having lived in London and Sydney too. The last 10 years have seen her present solo shows on Heat Radio, drivetime at Capital FM and weekends at Heart Northwest.

    A Mum of two boys and based in Manchester, Faye loves music, cake, travelling, gin and having a laugh. Co-hosting drive on Happy Radio with Ian Roberts is the perfect show for her friendly, funny and light hearted style.
    `,
    profileImage: '/presenters/Faye-Bamford.jpeg'
  },
]