import { useParams } from "react-router-dom";
import { eventsData } from "../data/eventsData";

export default function Show() {
  const { id } = useParams();
  const show = eventsData.find((event) => event.id === +id);

  if (!show) {
    return <div className="container mx-auto px-4 py-8">Show not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-md shadow-md overflow-hidden flex flex-col md:flex-row p-8 gap-6">
        <div className="relative max-h-64 max-w-64">
          <img
            src={show.image}
            alt={show.title}
            className="object-cover"
          />
        </div>
        <div className="flex flex-col justify-center gap-2">
          <h1 className="text-3xl font-bold text-gray-900 text-left mb-0 pb-0">{show.name}</h1>
          <div className="">
            <span className="text-gray-600 font-semibold">Time: </span>
            <span className="text-gray-800">{show.time}</span>
          </div>
          <div className="">
            <span className="text-gray-600 font-semibold">Day: </span>
            <span className="text-gray-800">{show?.day?.join(', ')}</span>
          </div>
          <div className="">
            <span className="text-gray-600 font-semibold">Show Description: </span>
            <span className="text-gray-800">{show.presenter}</span>
          </div>
          <p className="text-gray-700 leading-relaxed">{show.description}</p>
        </div>
      </div>
          {/* LISTEN AGAIN SECTION */}
          <div className="mt-4">
            <h2 className="text-xl font-bold text-gray-900">Coming Soon: Listen Again</h2>
            {/* ADD playback content here */}
          </div>
    </div>
  );
}
